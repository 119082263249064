/***** Custom reset *****/
	*, *:before, *:after {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	html, body {
		width: 100%;
		//height: 100%;
		//min-height: 100%;
	}


/***** Imports *****/
	@import 'fonts';
	@import 'general';


/***** Basic styling *****/
	body {
		position: relative;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 300;
        font-size: 18px;
		background: #eee;
		background: #fff;
		color: $text;
		overflow-y: scroll;

		@media all and (max-width: 899px) {
			&.open {
				overflow: hidden;
			}
		}
	}

	.wrapper {
		display: block;
		position: relative;
		margin: 0 auto;
		width: 100%;
		max-width: 1020px;
	}


$menu-height: 100px;
$max-width: 1120px;
$page-skew: 3deg;


/***** Logo *****/
	header {
		top: 0px;
		left: 0px;
		position: fixed;
		z-index: 11;
		width: 100%;
		display: inline-block;
display: none;

		#logo {
			display: inline-block;
			position: absolute;
			top: 50px;
			left: 15px;
			width: 300px;
			height: 80px;
			text-decoration: none;

			img {
				display: inline-block;
				width: 100%;
				height: 100%;
				border: 0px;
				background-color: transparent;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: left center;
				background-image: url('../img/logo.png');
			}
		}
	}


/***** Menu *****/
	nav {
		display: block;
		width: 100%;
		position: fixed;
		top: 0px;
		left: 0px;
		z-index: 2;

		&:before {
			content: '';
			position: fixed;
			display: inline-block;
			top: -50px;
			left: -100px;
			right: -100px;
			height: 150px;
			background: rgba($red, 1);
			transform-origin: 50% 50%;
			transform: rotate( $page-skew );
			z-index: 0;
			box-shadow: 0px 1px 10px 0px rgba(0,0,0, 0);
			transition: box-shadow 0.35s ease;
		}

		&:after {
			content: '';
			position: fixed;
			display: inline-block;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			background: rgba(#000, 0.6);
			// background: rgba($dark-red, 0.6);
			opacity: 0;
			pointer-events: none;
			z-index: -2;
			transition: opacity 0.35s ease;
		}
		
		ul, li {
			list-style: none;
		}
		a {
			text-decoration: none;
		}
		
		$menu-toggle-size: 60px;
		$menu-toggle-padding-width: 12px;
		$menu-toggle-padding-height: 15px;
		$menu-toggle-size-width: $menu-toggle-size - (2 * $menu-toggle-padding-width);
		$menu-toggle-size-height: $menu-toggle-size - (2 * $menu-toggle-padding-height);
		> a.menu-toggle {
			display: none;

			@media all and (max-width: 899px) {
				display: inline-block;
				width: $menu-toggle-size;
				height: $menu-toggle-size;
				position: absolute;
				top: 15px;
				right: 15px;
				padding: $menu-toggle-padding-height $menu-toggle-padding-width;

				&:before, > i, &:after {
					position: absolute;
					display: inline-block;
					left: $menu-toggle-padding-width;
					left: 50%;
					border-top: 4px solid #fff;
					width: $menu-toggle-size-width;
					height: 4px;
					transition: all 0.35s ease;
					transform: translate(-50%, -50%) rotate(0deg);
				}
				&:before {
					content: '';
					// top: $menu-toggle-padding-height;
					top: $menu-toggle-padding-height;
					// transform-origin: 0% 100%;
				}
				> i {
					top: 50%;
					opacity: 1;
					// transform: translate(0px, -2px);
				}
				&:after {
					content: '';
					// bottom: $menu-toggle-padding-height;
					bottom: $menu-toggle-padding-height;
					transform: translate(-50%, 50%) rotate(0deg);
					// transform-origin: 0% 0%;
				}
			}
		}
		
		> ul#menu {
			width: 100%;
			// max-width: $max-width;
			margin: 0px auto;
			padding: 30px 35px 0px 50px;
			
			> li.menu-item {
				text-align: right;
				width: 100%;
				
				> ul.menu {
					//
					
					li.menu-item {
						display: inline-block;
						
						a {
							position: relative;
							display: inline-block;
							text-transform: uppercase;
							font-size: 1.2em;
							font-weight: bold;
							color: #fff;
							text-shadow: 0px 1px 1px rgba(0,0,0, 0.3);
							padding: 15px 15px;
							
							&:after {
								content: '';
								position: absolute;
								bottom: 8px;
								left: 50%;
								right: 50%;
								opacity: 0.5;
								transition: all 0.35s ease;
								height: 3px;
								background: #fff;
								box-shadow:  0px 1px 1px 0px rgba(0,0,0, 0.2);
							}
							&:hover, &:focus {
								&:after {
									opacity: 1;
									left: 8px;
									right: 8px;
								}
							}
						}
						&.on a:after {
							left: 5px;
							right: 5px;
						}
						
						// Submenus
						ul.menu {
							li.menu-item {
								//
							}
						}
						
						&.on {
							//
						}
					}
				}
			}

			@media all and (max-width: 899px) {
				position: fixed;
				top: 90px;
				right: -300px;
				bottom: 0px;
				max-width: 300px;
				padding: 50px 25px 0px 25px;
				background-color: $dark-red;
				box-shadow: 0px 1px 15px 0px rgba(0,0,0, 0);
				z-index: -1;
				transition: right 0.35s ease, box-shadow 0.35s ease;
				overflow: auto;
				overflow-x: hidden;
				overflow-y: auto;
			    -webkit-overflow-scrolling: touch;

				> li.menu-item {
					> ul.menu {
						li.menu-item {
							width: 100%;
						}
					}
				}
			}

		}
	
		@media all and (max-width: 899px) {
			&.open {
				&:before {
					box-shadow: 0px 1px 10px 0px rgba(0,0,0, 0.15);
				}
				&:after {
					pointer-events: auto;
					opacity: 1;
				}

				> a.menu-toggle {
					&:before {
						width: (1.35 * $menu-toggle-size-width);
						// top: $menu-toggle-padding-width;
						top: 50%;
						transform: translate(-50%, -50%) rotate(45deg);
					}
					> i {
						opacity: 0;
					}
					&:after {
						width: (1.35 * $menu-toggle-size-width);
						// bottom: $menu-toggle-padding-width;
						bottom: 50%;
						transform: translate(-50%, 50%) rotate(-45deg);
					}
				}

				> ul#menu {
					right: 0px;
					box-shadow: 0px 1px 15px 0px rgba(0,0,0, 0.3);
					//todo
				}
			}
		}
	}

/***** Content *****/
	main {
		display: block;
		width: 100%;
		overflow: hidden;
	}

	section.page {
		display: block;
		overflow: hidden;
		transform-origin: 50% 50%;
		margin: -100px -50px 0px -50px;

		> .bg {
			position: absolute;
			top: -50px;
			bottom: 00px;
			left: 0px;
			right: 0px;
			transform-origin: 50% 50%;
			background-color: transparent;
			background-repeat: repeat;
			background-size: cover;
			background-position: center center;
		}

		.page-content {
			transform-origin: 50% 50%;
			padding: 100px 100px 200px;
			padding: 100px 80px 200px 100px;

			.content {
				width: 100%;
				max-width: $max-width - 100px;
				margin: 0px auto;
				
				text-align: justify;
			}
		}

		&:first-child {
			margin-top: -50px + $menu-height;
			padding-top: 80px;// + $menu-height;
		}

		&:last-child {
			.page-content {
				padding-bottom: 100px;
			}
		}
		
		&:nth-child(odd) {
			transform: rotate( $page-skew );
			background: transparent;
			
			> .bg {
				transform: rotate( -$page-skew );
				//background-image: url(https://placeholdit.imgix.net/~text?txtsize=16&bg=7AA45E&txtclr=89B270&txt=350%C3%97100%0Apx&w=350&h=100);
			}
			
			.page-content {
				transform: rotate( -$page-skew );
			}

			
			h1, h2, h3, h4 {
				text-align: left;
			}
		}

		&:nth-child(even) {
			transform: rotate( -$page-skew );
			background: transparent;
			
			> .bg {
				transform: rotate( $page-skew );
				//background-image: url(https://placeholdit.imgix.net/~text?txtsize=16&bg=C9785D&txtclr=E88C6A&txt=350%C3%97100%0Apx&w=350&h=100);
			}
		
			.page-content {
				transform: rotate( $page-skew );
			}
			
			h1, h2, h3, h4 {
				text-align: right;
			}
		}		

		@media all and (max-width: 799px) {
			margin: 0px !important;
			transform: none !important;
			position: relative;

			> .bg {
				transform: none !important;
				z-index: -1;
			}

			.page-content {
				transform: none !important;
				padding: 0px;
			}
		}
	}

	footer {
		position: relative;
		margin-top: -50px;
		border-top: 8px solid $red;
		background: $dark-red;
		width: 100%;
		display: inline-block;
		margin-bottom: -5px;

		> div {
			display: block;
			width: 100%;
			max-width: 1020px;
			margin: 0px auto;
			//padding: 20px;
			color: rgba(255,255,255,.5);

			a {
				color: rgba(255,255,255,.5);

				&:hover, &:focus {
					color: rgba(255,255,255,.8);
				}

				&.social-link {
					//margin-right: 20px;

					> i {
						font-size: 28px;
						margin-right: 8px;
					    vertical-align: -15%;
					}
				}
			}
		}
	}

	#vhw {
		width: auto;
		position: fixed;
		bottom: 10px;
		left: 100%;
		margin-left: -28px;
		transform: translate(0%, 0%);
		height: 24px;
		white-space: nowrap;
		color: rgba(#000, 0.3);
		text-decoration: none;
		transition: all 0.35s ease;
		padding-right: 5px;

		img {
			margin-right: 5px;
			border: 0px;
			vertical-align: -30%;
			opacity: 0.3;
		}

		&:hover, &:focus {
			//margin-left: 0px;
			transform: translate(-100%, 0%);
			color: rgba(#000, 0.5);
			// color: $purple;
			
			img {
				opacity: 0.5;
			}
		}
	}


/***** Modules *****/
	#mod_contact {
        a.action-main {
        	position: relative;
        	clear: right;
        	float: right;
        	margin-top: 25px;
			padding: 10px 15px 10px 45px;
			background: $red;
			color: #fff;
			font-size: 14px;
			font-weight: 500;
			text-decoration: none;
			text-transform: uppercase;
			box-shadow: 0px 1px 5px 0px rgba(0,0,0, 0);
			transition: all 0.15s ease;
			
			> .fa {
				position: absolute;
				left: 15px;
				top: 10px;
				font-size: 20px;
			}

			&:focus, &:hover {
				color: $red;
				background-color: #fff;
				box-shadow: 0px 1px 5px 0px rgba(0,0,0, 0.3);
			}
        }

		#do_mod_contact {
			position: absolute;
			opacity: 0;
			left: -2000px;
		}
	}

	#mod_testimonials {
		width: 100%;
		// max-width: 650px;
		margin: 0px auto;
		height: 500px;
		display: block;
		position: relative;
		pointer-events: none;

		blockquote {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: inline-block;
			width: 100%;
			quotes: "\201C" "\201E";
			text-align: justify;
			margin: 0px;
			font-weight: 500;
			font-style: italic;
			font-family: 'Alegreya', cursive;
			line-height: 1.4;
			background-color: rgba(255,255,255, 0.8);
			padding: 40px 40px 30px;
			font-size: 150%;

			opacity: 0;
			transition: opacity 1s ease;

			&.on {
				opacity: 1;
			}

			&::first-letter {
				padding-left: 40px;
			}

			&::before, &::after {
				font-size: 130px;
				color: rgba(0,0,0, 0.3);
				font-family: 'Alegreya', 'Vollkorn', serif;
			}
			&::before {
				position: absolute;
				line-height: 0px;
				content: open-quote;
				margin: 25px 0 0 -22px;
				color: $dark-red;
				color: $red;
			}
			&::after {
				display: none;
				z-index: -1;
				position: absolute;
				right: 0px;
				bottom: -20px;
				content: close-quote;
			}

			cite {
				float: right;
				clear: both;
				padding: 20px 0px 0px 25px;
				color: #666666;
				color: $red;
				font-style: normal;
				font-weight: normal;
				font-family: 'Josefin Sans', serif;
				font-size: 85%;
				font-size: 110%;
				text-transform: uppercase;
				text-align: right;

				&::before {
					content: '\2014';
					margin-right: 5px;
				}

				span {
					display: block;
					font-size: 60%;
					font-size: 50%;
					font-style: italic;
					font-weight: normal;
					color: $dark-red;
				}
			}

			@media all and (max-width: 899px) {
				padding: 30px 30px 20px;
				font-size: 130%;

				&::first-letter {
					padding-left: 30px;
				}
				
				&::before {
					font-size: 110px;
				}
			}
			@media all and (max-width: 449px) {
				font-size: 110%;
			}
		}
	}

	#mod_media {
		display: inline-block;
		position: relative;
		width: 100%;
		height: 500px;

		> a.nav_prev, > a.nav_next {
			position: absolute;
			top: 50%;
			transform: translate(0%, -50%);
			width: 50px;
			height: 50px;
			background-color: $dark-red;
			color: #fff;
			z-index: 4;
			border-radius: 50%;
			
			&:hover, &:focus {
				background-color: $red;
			}
			
			> i {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(0%, -50%);
				font-size: 24px;
			}
		}

		> a.nav_prev {
			left: 0px;
			
			> i {
				margin-left: -10px;
			}
		}

		> a.nav_next {
			right: 0px;
			
			> i {
				margin-left: -6px;
			}
		}

		figure {
			display: inline-block;
			left: 65px;
			right: 65px;
			height: 100%;
			position: absolute;
			z-index: 1;
			background: $red;
			opacity: 0;
			transition: opacity 0.5s ease;
			padding-bottom: 80px;
			
			img, video, audio, iframe {
				display: inline-block;
				width: 100%;
				left: 0px;
				top: 0px;
				height: 100%;
				background-color: $dark-red;
				z-index: 1;
			}

			audio + .controls {
				opacity: 1 !important;
			}

			img {
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
				
				& + .fullscreen {
					position: absolute;
					display: inline-block;
					top: 20px;
					right: 20px;
					width: 30px;
					height: 30px;
					background-color: rgba($dark-red, 1);
					border-radius: 50%;
					color: #fff;

					&:before {
						font-size: 18px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}

					&:hover, &:focus {
						background-color: rgba($red, 1);
					}
				}
			}
			video, audio {

				& + .controls {
					z-index: 2;
					display: inline-block;
					position: absolute;
					left: 0px;
					top: 0px;
					bottom: 80px;
					width: 100%;
					background: rgba(#333, 0.8);
					opacity: 0;
					transition: opacity 0.5s ease 0.15s;
					
					&.paused {
						opacity: 1;
					}
					
					&:hover {
						transition: opacity 0.5s ease 0s;
						opacity: 1;
					}
					
					.play {
						position: absolute;
						display: inline-block;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100px;
						height: 100px;
						background-color: rgba($dark-red, 1);
						border-radius: 50%;
						color: #fff;
						
						&:before {
							font-size: 60px;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-35%, -50%);
						}

						&.fa-pause:before, &.fa-undo:before {
							transform: translate(-50%, -50%);
						}

						&.fa-pause:before {
							font-size: 55px;
						}
						
						&:hover, &:focus {
							background-color: rgba($red, 1);
						}
					}
					
					.fullscreen {
						position: absolute;
						display: inline-block;
						top: 20px;
						right: 20px;
						width: 30px;
						height: 30px;
						background-color: rgba($dark-red, 1);
						border-radius: 50%;
						color: #fff;
						
						&:before {
							font-size: 18px;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
						
						&:hover, &:focus {
							background-color: rgba($red, 1);
						}
					}
					
					.volume {
						position: absolute;
						display: inline-block;
						bottom: 20px;
						right: 20px;
						width: 30px;
						height: 30px;
						background-color: rgba($dark-red, 1);
						border-radius: 50%;
						color: #fff;
						
						&:before {
							font-size: 18px;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
						
						&:hover, &:focus {
							background-color: rgba($red, 1);
						}
					}
					
					.volume-bar {
						position: absolute;
						display: inline-block;
						width: 20px;
						height: 100px;
						right: 25px;
						bottom: 60px;
						background-color: rgba($dark-red, 0.5);
						
						div {
							position: absolute;
							display: inline-block;
							height: 100%;
							bottom: 0%;
							width: 20px;
							background-color: $dark-red;
							transition: height 0.15s ease;
						}

						&.muted div {
							height: 0% !important;
						}
						
						&:hover, &:focus {
							background-color: rgba($red, 0.5);

							div {
								background-color: $red;
							}
						}
					}
					
					.time {
						position: absolute;
						display: inline-block;
						width: 100px;
						left: 25px;
						bottom: 55px;
						font-size: 14px;
						
						.currentTime {
							float: right;
							color: #fff;
						}
						
						.duration {
							float: right;
							color: $red;
							
							&:before {
								content: '/';
								padding: 0px 5px;
							}
						}
					}
					
					.position {
						position: absolute;
						display: inline-block;
						height: 20px;
						left: 25px;
						right: 70px;
						bottom: 25px;
						background-color: rgba($dark-red, 0.5);
						
						div {
							position: absolute;
							display: inline-block;
							width: 100%;
							left: 0px;
							height: 20px;
							background-color: $dark-red;
							transition: width 0.15s linear;
						}
						
						&:hover, &:focus {
							background-color: rgba($red, 0.5);

							div {
								background-color: $red;
							}
						}
					}
				}
			}
			
			figcaption {
				position: absolute;
				bottom: 0px;
				left: 0px;
				width: 100%;
				height: 80px;
				padding: 15px;
				white-space: nowrap; 
				overflow: hidden;
				text-overflow: ellipsis;
				font-weight: 700;
				text-transform: uppercase;
				color: #fff;
				
				> span {
					width: 100%;
					float: left;
					clear: both;
					white-space: nowrap; 
					overflow: hidden;
					text-overflow: ellipsis;
					font-weight: normal;
					text-transform: initial;
					color: rgba(0,0,0, 0.8);
				}
			}
			
			&.on {
				z-index: 2;
				opacity: 1;
			}
		}
	}

	.in-fullscreen #mod_media figure.on {
		position: fixed;
		top: 0px !important;
		left: 0px !important;
		right: 0px !important;
		bottom: 0px !important;
		
		img, video, audio, iframe {
			background-color: #0c0c0c;
		}
	}


/***** Status & Form *****/
    .statusbox {
        width: 100%;
        display: inline-block;
        //margin-bottom: 30px;
        //padding: 15px;
        position: relative;
		margin-bottom: 25px;
    	padding: 25px;
    	padding-left: 65px;
    	background-color: #fff;
        border: 0px solid #888;
        color: #888;
	    box-shadow: 0px 1px 4px 0px rgba(0,0,0, 0.2);

		&:before {
			content: '\f05a'; // info
			border-color: inherit;
			color: inherit;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: 24px;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 24px;
			position: absolute;
			left: 35px;
			top: 50%;
			transform: translate(-50%, -50%);
		}

        &.help {
            border: 0px solid #3574B2;
            color: #3574B2;
            &:before {
				content: '\f059';
            }
        }
        &.ok {
            border: 0px solid #4CAF50;
            color: #4CAF50;
            &:before {
				content: '\f058';
            }
        }
        &.warning {
            border: 0px solid #FB8C00;
            color: #FB8C00;
            &:before {
				content: '\f06a';
            }
        }
        &.error {
            border: 0px solid #D32F2F;
            color: #D32F2F;
            &:before {
				content: '\f057';
            }
        }
    }


    input, select, textarea, button, .button {
        &:not([class*="cke_"]) {
            display: inline-block;
            padding: 11px 16px;
            font-size: 1.1rem;
            outline: none;
            vertical-align: top;
            color: rgba(0,0,0, 0.54);
        }
        &[type="file"] {
            background: #fff;
        }
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), select, textarea {
        &:not([class*="cke_"]) {
            width: 100%;
        }
    }
    textarea {
        &:not([class*="cke_"]) {
           height: 85px;
       }
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), select, textarea {
        &:not([class*="cke_"]) {
            border: 0px solid rgba(0,0,0, 0.3);
            box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3);

            &:focus {
            	padding: 10px 15px;
                border: 1px solid rgba(#1E88E5, 1);
                box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3),
                    0px 0px 2px 1px rgba(#03A9F4, 0.8);
            }
        }
    }

    input[type="checkbox"], input[type="radio"] {
        width: 0px;
        height: 0px;
        opacity: 0;
        padding: 0px;
        margin: 0px;

        & + label {
            display: inline-block;
            position: relative;
            height: 20px;
            padding-left: 30px;
            font-size: 1.01rem;
            outline: none;
            margin-right: 30px;

            &:before {
                content: '';
                position: absolute;
                z-index: 2;
                top: 0px;
                left: 0px;
                width: 20px;
                height: 20px;
                background: #fff;
                //border: 1px solid rgba(0,0,0, 0.3);
                border-radius: 3px;
                box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3);
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 3;
                opacity: 0;
                transition: all 0.15s ease 0s;
                top: 2px;
                left: 7px;
                width: 7px;
                height: 13px;
                border-bottom: 3px solid #ee2a7b;
                border-right: 3px solid #ee2a7b;
                transform: rotate(45deg);
                transform-origin: 50% 50%;
            }
        }
        &[type="radio"] + label {
            &:before, &:after {
                border-radius: 50%;
            }
            &:after {
                top: 5px;
                left: 5px;
                width: 10px;
                height: 10px;
                border: 0px;
                transform: rotate(0deg);
                transform-origin: 50% 50%;
                background: #ee2a7b;
            }
        }

        &:hover + label {
            //
        }

        &:focus + label {
            &:before {
                border: 1px solid rgba(#1E88E5, 1);
                box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3),
                    0px 0px 2px 1px rgba(#03A9F4, 0.8);
            }
        }

        &[type="checkbox"]:checked + label:after {
            opacity: 1;
        }

        &[type="radio"]:checked + label:after {
            opacity: 1;
        }
    }

    button, .button, input[type="submit"] {
        &:not([class*="cke_"]) {
            position: relative;
            cursor: pointer;
            background: #F5F5F5;
            color: rgba(0,0,0, 0.54);
            border: 1px solid rgba(0,0,0, 0.1);
            box-shadow: inset 0px 1px 0px 0px rgba(255,255,255, 0.3), 0px 1px 3px -1px rgba(0,0,0, 0.4);
            border-radius: 3px;
            text-decoration: none;
            padding: 12px 15px;
            height: 45px;

            &:hover, &:focus {
                background: #FAFAFA;
            }
            &:active {
                background: #E0E0E0;
                padding-top: 13px;
                padding-bottom: 11px;
                box-shadow: inset 0px 1px 1px 0px rgba(0,0,0, 0.1);

                .fa {
                    margin-top: -9px;
                }
            }

            &.ico {
                padding-left: 37px;
            }
            &.yellow {
                background: #FFA726;

                &:hover, &:focus {
                    background: #FFB74D;
                }
                &:active {
                    background: #FB8C00;
                }
            }
            &.light-blue {
                background: #B3E5FC;

                &:hover, &:focus {
                    background: #E1F5FE;
                }
                &:active {
                    background: #4FC3F7;
                }
            }
            &.add {
                background: #4CAF50;

                &:hover, &:focus {
                    background: #66BB6A;
                }
                &:active {
                    background: #388E3C;
                }
            }
            .fa {
                position: absolute;
                top: 50%;
                margin-top: -10px;
                left: 12px;
                display: inline-block;
                width: 20px;
                height: 20px;
                font-size: 20px;
            }
        }
    }

	.input-range {
		position: relative;
		width: 100%;
		max-width: 200px;
		height: 40px;
		display: inline-block;
		
		.bar {
			display: inline-block;
			position: absolute;
			top: 3px;
			height: 8px;
			left: 7px;
			right: 7px;
			background-color: #ddd;
			z-index: 1;
			&:before {
				content: '';
				position: absolute;
				top: 0px;
				left: -4px;
				width: 4px;
				height: 8px;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
				background: inherit;
			}
			&:after {
				content: '';
				position: absolute;
				top: 0px;
				right: -4px;
				width: 4px;
				height: 8px;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				background: inherit;
			}
		}
		
		.bar-selection {
			display: inline-block;
			position: absolute;
			top: 3px;
			height: 8px;
			left: 7px;
			right: 7px;
			background-color: #ddd; // #aaa
			z-index: 2;
			transition: background-color 0.15s ease;
		}
		
		.handle-min, .handle-max {
			display: inline-block;
			position: absolute;
			top: 0px;
			left: 0px;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background-color: #fff;
			box-shadow: 0px 1px 2px 0px rgba(0,0,0, 0.3);
			z-index: 3;
			cursor: w-resize;
			outline: none;
			
			&:focus {
    			box-shadow: 0px 0px 0px 1px #1e88e5, 0px 0px 2px 2px rgba(3,169,244, .8);
    			
    			&:after {
    				color: $red;
    				// box-shadow: 0px 0px 0px 1px #1e88e5, 0px 0px 2px 2px rgba(3,169,244, .8);
	    		}
    		}

			&:after {
				content: attr(data-prefix) '' attr(data-val) '' attr(data-affix);
				position: absolute;
				top: 100%;
				left: 50%;
				white-space: nowrap;
				margin-top: 6px;
				font-size: 11px;
				font-weight: 500;
				background-color: #fff;
				color: #bbb;
				padding: 3px 5px 1px;
				transform: translate(-50%, 0%);
				border-radius: 3px;
				//box-shadow: 0px 1px 1px 0px rgba(0,0,0, 0.15);
				box-shadow: 0px 1px 2px 0px rgba(0,0,0, 0.15);
				z-index: -1;
				transition: color 0.15s ease;
			}
			&:before {
				content: '';
				position: absolute;
				top: 100%;
				left: 50%;
				margin-top: 3px;
				transform: translate(-50%, 0%);
				width: 6px;
				height: 3px;
				border-color: transparent;
				border-style: solid;
				border-width: 3px;
				border-top: 0;
				border-bottom-color: #fff;
			}

			&:hover:after {
				color: $red;
			}
		}
		
		&.active {
			.bar-selection {
				background-color: $red;
			}
			.handle-min, .handle-max {
				&:after {
					color: $red;
				}
			}
		}
	}

    .form-label {
        display: inline-block;
        width: 100%;
        padding: 10px 0px 5px;
        font-size: 1.1rem;
       
        &.error {
            color: #D32F2F;
        }
    }

    .form-input {
        display: inline-block;
        width: 100%;
        padding: 5px 0px 20px;
    }


/***** Responsiveness *****/
	@media all and (max-width: 1023px) {

		/*
		img {
			max-width: 100%;
			height: auto !important;
		}
		*/

	}


