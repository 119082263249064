/***** Variables *****/
	$red: #df0101;
	$dark-red: #b10101;
	$light-grey: #bdc3c7;
	$grey: #414141;

	$text: #1C1823;
	$nearly-white: #F8F7FF;
	$leegpng: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQI12NgYAAAAAMAASDVlMcAAAAASUVORK5CYII=);

/***** Widths *****/
	@for $i from 1 through 12 {
		$value: $i;
		.col#{$value}-12 {
			width: percentage($value/12) !important;
		}
	}
	.colbreak {
		width: 100%;
		clear: both;
		padding: 0px !important;
	}

	@media all and (max-width: 1023px) {
		// in 3's
		.col1-12, .col2-12, .col3-12, .col4-12 {
			width: percentage(1/3) !important;
		}
		.col5-12, .col6-12, .col7-12 {
			width: percentage(1/2) !important;
		}
		.col8-12, .col9-12 {
			width: percentage(2/3) !important;
		}
		.col10-12, .col11-12, .col12-12 {
			width: 100% !important;
		}

		.blockstyle-margin {
			display: none !important;
		}
	}

	@media all and (max-width: 768px) {
		// in 2's
		.col1-12, .col2-12, .col3-12, .col4-12, .col5-12, .col6-12, .col7-12 {
			width: percentage(1/2) !important;
		}
		.col8-12, .col9-12, .col10-12, .col11-12, .col12-12 {
			width: 100% !important;
		}
	}

	@media all and (max-width: 450px) {
		// in 1's
		.block-content {
			width: 100% !important;
		}
	}

/***** Blocks *****/
	.block-content {
		float: left;
		display: inline-block;
		width: 100%;
		padding: 25px;
		text-align: justify;
		font-family: 'Josefin Sans', sans-serif;
		font-weight: 300;
		font-size: 18px;
		line-height: 1.5;

		h1, h2, h3, h4, h5, h6 {
			font-family: 'Alegreya', serif;
			font-weight: 700;
			//font-weight: normal !important;
			//text-transform: uppercase;
			margin-bottom: 20px;
		}
		h1 {
			font-size: 3.6em;
		}
		h2 {
			font-size: 2.4em;
		}
		h3 {
			font-size: 1.8em;
		}
		h4 {
			font-size: 1.2em;
		}
		a {
			text-decoration: none;

			&:hover, &:focus {
				text-decoration: underline;
			}
		}
	}
	.blockstyle-transparent {
		background: transparent;
		color: $text;
		h1, h2, h3, h4, h5, h6, a {
			color: $red;
		}
		a {
			color: $dark-red;
		}
	}
	.blockstyle-white {
		background: rgba(255,255,255, 0.8);
		color: $text;
		h1, h2, h3, h4, h5, h6, a {
			color: $red;
		}
		a {
			color: $dark-red;
		}
	}
	.blockstyle-black {
		background: rgba(0,0,0, 0.3);
		color: #fff;
		h1, h2, h3, h4, h5, h6, a {
			color: $red;
		}
		a {
			color: $dark-red;
		}
	}
	.blockstyle-red {
		background: rgba($red, 0.8);
		color: #fff;
		h1, h2, h3, h4, h5, h6, a {
			color: #fff;
		}
		a {
			color: #fff;
		}
	}
	.blockstyle-dark-red {
		background: rgba($dark-red, 0.8);
		color: #fff;
		h1, h2, h3, h4, h5, h6, a {
			color: #fff;
		}
		a {
			color: #fff;
		}
	}


/***** Style elements *****/
	.left {
		float: left;
	}
	.right {
		float: right;
	}
	.centered {
		display: block;
		margin: 0px auto;
	}

	.text-center {
		text-align: center;
	}

	.text-right {
		text-align: right;
	}


/***** Extra functions *****/
@function sqrt($r) {
  $x0: 1; // initial value
  $solution: false;
 
  @for $i from 1 through 10 {
    @if abs(2 * $x0) < 0,00000000000001 { // Don't want to divide by a number smaller than this
      $solution: false;
    }
 
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0) !global;
 
    @if ( abs($x1 - $x0) / abs($x1)) < 0,0000001 { // 7 digit accuracy is desired
      $solution: true;
    }
 
    $x0: $x1;
  }
 
  @if $solution == true {
    // If $r is negative, then the output will be multiplied with <a href="http://en.wikipedia.org/wiki/Imaginary_number">i = √-1</a>
    // (√xy = √x√y) => √-$r = √-1√$r
    @if $r < 0 {
      @return $x1 #{i};
    }
    @else {
      @return $x1;
    }
  }
  @else {
    @return "No solution";
  }
}